import React from "react";
import Home from "./components/Home";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Form from "./components/Form";
import PrivacyForm from "./components/PrivacyForm";

export default function App() {

  return  (
    <Router>
      <Routes>
        <Route exact path="/" element ={<Home/>}></Route>
        <Route exact path="/Contact" element ={<Form/>}></Route>
        <Route exact path="/Privacy-Policy" element ={<PrivacyForm/>}></Route>
      </Routes>
    </Router>
  );
}