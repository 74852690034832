import React, { useEffect } from 'react';
import './privacy.css';

const Privacy = () => {
  useEffect(() => {
    // Scroll to the privacy policy section when the component mounts
    const privacyPolicySection = document.getElementById('privacy-policy');
    if (privacyPolicySection) {
      privacyPolicySection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  return (
    <div className="PrivacyContainer" id='privacy-policy'>
      <h1>Privacy Policy</h1>

      <p>
        Welcome to [RoseGardenSkincare] ("we," "our," or "us"). This Privacy Policy outlines how we collect, use,
        disclose, and safeguard your personal information when you visit our website [RoseGardenSkincare.com] ("Website").
        By using the Website, you agree to the terms outlined in this Privacy Policy.
      </p>

      <h2>1. Information We Collect</h2>

      <p>
        We may collect various types of information from and about users of our Website, including:
      </p>

      <ul>
        <li>Personal Information: such as your name, email address, and other identifiable information you provide voluntarily.</li>
        <li>Usage Information: including your IP address, browser type, and other data collected through cookies and similar technologies.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>

      <p>
        We use the information we collect for various purposes, including but not limited to:
      </p>

      <ul>
        <li>Providing and improving our services.</li>
        <li>Customizing your experience on our Website.</li>
        <li>Communicating with you and responding to your inquiries.</li>
      </ul>

      <h2>3. Disclosure of Your Information</h2>

      <p>
        We may share your information with third parties, including but not limited to:
      </p>

      <ul>
        <li>Service providers who assist us in operating our Website and providing services.</li>
      </ul>

      <h2>4. Your Choices</h2>

      <p>
        You have the right to make certain choices regarding your information. You can:
      </p>

      <ul>
        <li>Control how we collect and use certain information through your browser settings.</li>
        <li>Opt out of certain data collection practices.</li>
      </ul>

      <h2>5. Security</h2>

      <p>
        We implement reasonable security measures to protect your personal information. However, no data transmission
        over the internet is completely secure, and we cannot guarantee the security of your information.
      </p>

      <h2>6. Links to Other Websites</h2>

      <p>
        Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content
        of these third-party sites. Please review the privacy policies of those websites for more information.
      </p>

      <h2>7. Changes to Our Privacy Policy</h2>

      <p>
        We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting.
        Your continued use of the Website after such changes constitutes your acceptance of the revised policy.
      </p>

      <h2>8. Contact Information</h2>

      <p>
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:sarahandjake@RoseGardenSkincare.com">sarahandjake@RoseGardenSkincare.com</a>.
      </p>

      <p>Last Updated: [01/09/2024]</p>
    </div>
  );
}

export default Privacy;
