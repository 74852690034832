import React, { useState }  from "react"
import axios from 'axios';
import './contact.css';


const Contact = (props) => {
  const [submitted, setSubmitted] = useState({name: '', email: '', comment: ''});
  const [toggled, setToggled] = useState(false);
  const contactAPI = "/contact";
  const handleSubmit = (e) => { 
    e.preventDefault();  
    setToggled(true);

    const data = { name: submitted.name, email: submitted.email, comment: submitted.comment };    
    axios.post(contactAPI, data, {withCredentials: true})
    .then((result) => {
      setSubmitted(result.data)
      if (result.data.Status === 'Invalid')  
      alert('Invalid User');  
      else  
      props.history.push('/Contact')
    });
  }

  const onChange = (e) => {    
    e.persist();
    setSubmitted({...submitted, [e.target.name]: e.target.value});
  }

  if (toggled) {
    return (
      <>
      <div className="thankContainer">
        <div className="thankMessage">Thank you!</div><br></br>
        <div className="touchMessage">We'll be in touch soon.</div>
      </div>
      </>
    );
  }
  
  return (
  <div className="contactWrapper">
    <form className="contactContainer" onSubmit={handleSubmit} method="POST" target="_blank">Contact Us
      <div className="nameContainer">
        <input type="text" value={submitted.name} onChange={ onChange } placeholder="Your name" name="name" className="nameText" required/>
      </div>
      <div className="emailContainer">
        <input type="email" value={submitted.email} onChange={ onChange } placeholder="Email" name="email" className="emailText" required/>
      </div>
      <div className="messageContainer">
        <textarea type="comment" value={submitted.comment} onChange={ onChange } placeholder="Your message" name="comment" className="messageText" required/>
      </div>
      <div className="sendContainer">
        <button className="submitButton" type="submit" onClick={Contact}>
          Send a message
        </button>
      </div>
      </form>
    </div>
  );
};

export default Contact;