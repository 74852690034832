import React from "react"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Main from "../components/Main"

export default function Home() {
    return (
        <div className="container">
          <Navbar/>
          <Main/>
          <Footer/>
        </div>
      );
    }