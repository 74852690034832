import React from "react";
import Product from '../Images/ROSE_NEW_WATER.png';
import leftProduct from '../Images/Copy_of_Rose_Water_Cleanser_product_ad_1-3.png';
import middleProduct from '../Images/Copy_of_Template_2_for_gel_cleanser.jpg';
import rightProduct from '../Images/Rose_Water_Cleanser_product_ad_2.jpg';
import boxproduct from '../Images/20230911-DSC08370.jpg';
import backproduct from '../Images/20230911-DSC08386.jpg';
import review1Image from '../Images/review1.jpg';
import review2Image from '../Images/review2.JPG';
import review3Image from '../Images/review3.JPG';
import './main.css';

export default function Main() {
  return (
    <div>
      <div className="mainProduct">
        <div className="mainText">
          <h5>MADE FOR Sensitive Skin</h5>
          <h1>Rose Water Facial Cleanser</h1>
          <h3>If you're struggling with sensitive skin and feeling weighed down, it's time to start anew with a smooth, silky complexion. Our product is specifically designed to address the unique needs of sensitive skin, so you can feel confident and comfortable in your own skin once again.</h3>
          <a href="https://www.amazon.com/dp/B0BYCFVDQT?ref=myi_title_dp"><button className="mainLink">$24.99 on Amazon</button></a>
        </div>
        <img className="mainImg" src={Product} alt="Main product" />
        <div className="mainDetails">
          <h2 className="mainTitle">Product Description</h2>
          <h3 className="mainDesc">Contains anti-inflammatory ingredients, antioxidants<br></br>plant extracts to detoxify and purify the skin.</h3>
          <h5 className="mainNet">Net wt:  3.38 fl. oz. / 100 ML</h5>
          <h5 className="mainVegan">Non-toxic | Vegan | Cruelty-Free</h5>
        </div>
      </div>
      <div className="second-container">
        <h2 className="transformText">
          Transform your skincare routine
        </h2>
        <div className="image-container">
          <a href="https://www.amazon.com/dp/B0BYCFVDQT?ref=myi_title_dp" target="_blank" rel="noopener noreferrer">
            <img src={leftProduct} alt="Product 1" />
            <p className="image-text">Transform your skincare routine with confidence</p>
          </a>
          <a href="https://www.amazon.com/dp/B0BYCFVDQT?ref=myi_title_dp" target="_blank" rel="noopener noreferrer">
            <img src={middleProduct} alt="Product 2" />
            <p className="image-text">Gentle care for sensitive skin</p>
          </a>
          <a href="https://www.amazon.com/dp/B0BYCFVDQT?ref=myi_title_dp" target="_blank" rel="noopener noreferrer">
            <img src={rightProduct} alt="Product 3" />
            <p className="image-text">Nourish and purify with our gel cleanser</p>
          </a>
        </div>
      </div>
      <div className="side-by-side-container">
        <div className="left-text">
        <h2>Discover a Gentle Solution for Your Sensitive Skin</h2>
        <p>Introducing our Rose Water Facial Cleanser, a soothing and effective skincare solution crafted for individuals with sensitive skin. Experience the transformative power of natural ingredients, leaving your skin feeling refreshed, revitalized, and cared for.</p>
        </div>
        <div className="right-image">
          <img src={boxproduct} alt="Right Product" />
        </div>
      </div>
      <div className="side-by-side-container">
        <div className="left-image">
          <img src={backproduct} alt="Left Product" />
        </div>
        <div className="right-text">
        <h2>Elevate Your Skincare Routine with Premium Ingredients</h2>
        <p>Immerse yourself in the luxury of our skincare line, curated to provide a seamless blend of nourishment and gentleness. Our commitment to quality ensures your skincare journey is a delightful experience, leaving you with radiant and healthy-looking skin.</p>
        </div>
      </div>
      <div className="third-container">
        <h2 className="helpText">
          Rose Water Facial Cleanser can help with:
        </h2>
      </div>
      <div className="reviews-container">
        <img src={review1Image} alt="Review 1" />
        <img src={review2Image} alt="Review 2" />
        <img src={review3Image} alt="Review 3" />
      </div>
    </div>
  );
}
