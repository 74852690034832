import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Product from '../Images/rose_garden_skincare_logo-4.png';
import './navbar.css'

export default function Navbar() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="navTool">
          <h1 className="navTitle" onClick={() => navigate("/")}>
            Home
          </h1>
        <Link to="/">
          <img className="navLogo" src={Product} alt="Main product" />
        </Link>
          <h1 className="navHome" onClick={() => navigate("/Contact")}>
            Contact
          </h1>
      </div>
    </React.Fragment>
  );
}
