import React from "react"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Contact from "../components/Contact"

export default function Form() {
    return (
        <div className="container">
          <Navbar/>
          <Contact/>
          <Footer/>
        </div>
      );
    }