import React from "react"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Privacy from "../components/Privacy"

export default function PrivacyForm() {
    return (
        <div className="container">
          <Navbar/>
          <Privacy/>
          <Footer/>
        </div>
      );
    }