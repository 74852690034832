import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import './footer.css'

export default function Footer() {
    return (
      <div className="footer">
        <p className="copyright">&copy; 2024 Rose Garden Skincare LLC. All Rights Reserved.</p>
  
        {/* Privacy Policy Link */}

        <h1><Link to="/Privacy-Policy" className="footLink">
          Privacy Policy
        </Link>
        </h1>
  
        {/* Social Media Links */}
        <div className="social-media">
          <a href="https://www.instagram.com/rosegarden.skincare/">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a href="https://twitter.com/RoseGardenSkin">
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100088604415206">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a href="https://www.tiktok.com/@rosegardenskincare?lang=en">
            <ion-icon name="logo-tiktok"></ion-icon>
          </a>
        </div>
      </div>
    );
  }
  